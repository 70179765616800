import Vue from 'vue';

export default {
    getTiposIncidencias(parametros){
        return Vue.axios.get("catalogo/tipos-incidencias/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getTipoIncidencia(id){
        return Vue.axios.get("catalogo/tipos-incidencias/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateTipoIncidencia(parametros){
        return Vue.axios.put("catalogo/tipos-incidencias", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteTipoIncidencia(id){
        return Vue.axios.delete("catalogo/tipos-incidencias/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addTipoIncidencia(parametros){
        return Vue.axios.post("catalogo/tipos-incidencias", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}