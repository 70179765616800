<template>
    <div class="component-main">
        <!-- <span>
            <i ref="icon" class="material-icons icon-size">lens</i> 
            <label class="label-font">{{ label }}</label>
        </span> -->
        <div class="item-icon">
            <i ref="icon" class="material-icons icon-size">lens</i> 
        </div>

        <div class="item-label">
            <label class="label-font">{{ label }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bullet-component',
    data() {
        return {

        }
    },
    props: {
        color: {
            type: String,
            required: true,
            default: '#000000'
        },
        label: {
            type: String,
            required: true,
            default: 'Item'
        },
        iconSize: {
            type: [String],
            required: false,
            default: '8px'
        }
    },
    methods: {
        init() {
            this.$refs.icon.style.color = this.color;
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style>
    .component-main {
        /* margin-bottom: 15px; */
        display: inline-block;
        width: 100%;
    }

    .item-icon {
        width: 15%;
        float: left;
        margin-bottom: 7px;
    }

    .item-label {
        width: 85%;
        float: right;
        margin-bottom: 7px;
    }

    .icon-size {
        font-size: 21px !important;
        padding-right: 5px;
    }

    .label-font {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 115.9%;
        /* or 16px */

        color: #57575C;
    }
</style>