
<template>
  <div id="pageTable">
    <v-container xl fluid class="px-0">
      <v-flex xs12 sm12 md12>
        <ck-calendar
          :show-date="showDate"
          :startingDayOfWeek="1"
          weekdayNameFormat="long"
          :events="registros"
          :holidays="festivos"
          class="theme-default"
        >
          <ck-calendar-header
            slot="header"
            slot-scope="{ headerProps }"
            :header-props="headerProps"
            @input="setShowDate"
          />
        </ck-calendar>
      </v-flex>
    </v-container>

    <floating-view :items="catalogo">
      <div slot="title" style="margin-top: -35px !important;">
        <v-icon color="#2DB9D1">mdi-poll</v-icon>
        <!-- <img src="/static/icon/tipo-incidencia.png" /> -->
        <span class="floating-title">Tipos de incidencia</span>
      </div>

      <div slot="body">
        <Bullet v-for="item in catalogo" :key="item.id" :color="item.color" :label="item.nombre" />
      </div>
    </floating-view>
  </div>
</template>

<script>
import CkCalendar from "@/components/calendar/CkCalendar.vue";
import CkCalendarHeader from "@/components/calendar/CkCalendarHeader.vue";
import FloatingView from "@/components/FloatingView.vue";
import Bullet from "@/components/Bullet.vue";
import Utils from "@/util/utils";

import incidenciaApi from "../api/incidencias";
import tipoApi from "../api/tiposIncidencias";
import festivoApi from "../api/dias_festivos";
import GetUrlImg from "@/plugins/Urlimagen";

export default {
  components: {
    CkCalendar,
    CkCalendarHeader,
    FloatingView,
    Bullet
  },
  data() {
    return {
      showDate    : new Date(),
      festivos    : [],
      registros   : [],
      catalogo    : [],
      anio_actual : 0,
      params_base : { activo: true, paginate: false },
      datosLogin  : null,
      baseUrl     : GetUrlImg.getURL(),
    };
  },
  methods: {
    async getData() {
      this.datosLogin = this.$session.get("usuario");
      let today = new Date();
      this.anio_actual = today.getFullYear();
      let mes = today.getMonth() + 1;

      let cliente = null;
      let empresa = null;
      let sucursal = null;
      let departamento = null;

      if (this.datosLogin.rol.name == "admin") {
        cliente = this.datosLogin.cliente_id;
      } else if(this.datosLogin.rol.name == "admin-empresa"){
        empresa = this.datosLogin.empresa_id;
      }else if(this.datosLogin.rol.name == "admin-sucursal" || this.datosLogin.rol.name == "admin-depto"){
        empresa = this.datosLogin.empresa_id;
        sucursal  = this.datosLogin.sucursal_id;
      }else{
        empresa = this.datosLogin.empresa_id;
        sucursal  = this.datosLogin.sucursal_id;
        departamento = this.datosLogin.departamento_id;
      }
      let params = {
        cliente_id: cliente,
        empresa_id: empresa,
        sucursal_id: sucursal,
        departamento_id: departamento
      };
      this.params_base = Object.assign(this.params_base, params);


      tipoApi
        .getTiposIncidencias(this.params_base)
        .then(response => {
          this.catalogo = response.data;
        })
        .catch(err => {
          console.log(err);
        });

      this.getFestivos(this.anio_actual);
      this.getIncidencias(mes);
    },
    setShowDate(d) {
      this.showDate = d;
      let anio = this.showDate.getFullYear();
      let mes = this.showDate.getMonth() + 1;

      if (anio != this.anio_actual) {
        this.anio_actual = anio;
        this.getFestivos(anio);
      }
      this.getIncidencias(mes);
    },
    getFestivos(anio) {
      const params_festivo = Object.assign(
        { min_fecha: anio + "-01-01", max_fecha: anio + "-12-31" },
        this.params_base
      );

      festivoApi
        .getDias(params_festivo)
        .then(response => {
          let formateado = response.data;

          formateado.forEach(element => {
            element["startDate"] = element["fecha"];
            element["title"] = element["nombre"];
            element["subtitle"] = element["descripcion"];
          });

          this.festivos = formateado;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getIncidencias(m) {
      m           = m.toString();
      let mes     = m.length == 1 ? "0" + m : m;
      let date    = this.anio_actual + "-" + mes + "-01";
      let endDay  = new Date(this.anio_actual, mes, 0);

      let start   = Utils.date_format(new Date(date));
      let end     = Utils.date_format(endDay);

      this.registros = [];

      let params_add = {
        rol_name        : this.datosLogin.rol.name,
        usuario_id      : this.datosLogin.id,
        include_padres  : true,
        min_fecha_final : start,
        max_fecha_final : end,
        estatus_valor   : 'aprobado'
      };

      const params_incidencia = Object.assign(params_add, this.params_base);

      let parametros = {
        sucursal_id :null,
        departamento_id: null

      }

      if (this.datosLogin.rol.name == "admin-sucursal") {
        parametros.sucursal_id = this.datosLogin.sucursal_id;
      }
      else if (this.datosLogin.rol.name == "admin-depto" || this.datosLogin.rol.name == "empleado"){
        parametros.sucursal_id = this.datosLogin.sucursal_id;
        parametros.departamento_id  = this.datosLogin.departamento_id;
      }

      const parametros_incidencia = Object.assign(params_incidencia, parametros);

      incidenciaApi
        .getIncidencias(parametros_incidencia)
        .then(response => {
          let formateado = response.data;

          response.data.forEach(element => {
            let newItem = {
              id          : element["id"],
              startDate   : element["fecha_inicio"],
              endDate     : element["fecha_final"],
              title       : element["nombre_completo"],
              subtitle    : element["nombre_depto"],
              color       : element["color"],
              photo       : this.baseUrl + element["foto"]
            };
            
            this.registros.push(newItem);
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped>
@import "/static/css/default.css";

#padre {
  padding-left: 40%;
}
#loading {
  display: table-cell;
  vertical-align: middle;
}
#contenedor {
  margin-top: -6%;
}

.floating-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1E2245;

  padding-left: 20px;
}

.titulo {
  font-family: "Montserrat" !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #0f3043 !important;
  letter-spacing: -0.68px;
  line-height: 34px;
}
.btnGuardar {
  font-family: "Montserrat";
  font-size: 13px !important;
  font-weight: 700 !important;
  background-color: #183799 !important;
  border-radius: 23px 23px 23px 23px;
  color: white !important;
  width: 70% !important;
  height: 40px !important;
}
.btnCerrar {
  font-family: "Montserrat";
  font-size: 13px !important;
  font-weight: 700 !important;
  background-color: white !important;
  border-radius: 23px 23px 23px 23px;
  color: rgba(121, 141, 152, 0.6);
  width: 70% !important;
  height: 40px !important;
}
.invertirColor {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.54);
}
.tamIcono {
  height: 24px !important;
  align-self: center !important;
}
.iconoDelgado {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
}
.tipoLetra700Azul {
  font-weight: 700;
  letter-spacing: -0.34px;
}
</style>
