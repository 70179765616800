<template>
    <div ref="floatingView" class="component-card" :class="[isOpen ? 'visualizar-scroll':'']" v-draggable>
        <span class="btn-close" v-if="isOpen">
            <i @click="closeCard" class="material-icons close-color " style="float: right;">expand_less</i>
        </span>

        <span class="btn-close" v-else>
            <i @click="openCard" class="material-icons close-color " style="float: right;">expand_more</i>
        </span>

        <div class="title-container">
            <slot name="title" />
        </div>

        <div ref="floatingViewContainer" class="body-container">
            <transition name="slide-fade">
                <slot v-if="isOpen" name="body" />
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'floating-view',
    props: {
        items: { type: Array, default: () => [] },
    },
    data() {
        return {
            isOpen : true,
        }
    },
    methods: {
        openCard() {
            this.isOpen = true;
            this.$refs.floatingViewContainer.style.visibility   = 'visible';
            let largo                                           = ( this.items.length * 45) + 130;
            this.$refs.floatingView.style.height                = largo + 'px';
        },
        closeCard() {
            this.isOpen = false;

            setTimeout(() => {
                this.$refs.floatingView.style.height                = '65px';
                this.$refs.floatingViewContainer.style.visibility   = 'hidden';
            }, 200);
        },
    }
}
</script>

<style scoped>
    .component-card {
        width: 296px !important;
        max-height: 900px !important;

        padding-top: 28px;
        padding-bottom: 38.42px;
        padding-left: 32px;
        padding-right: 31.5px;

        border-radius: 20px;
        background: rgba(255, 255, 255, 0.8);

        /* Add shadows to create the "card" effect */
        box-shadow: 0px 4px 10px #C4C4C4;
        transition: 0.3s;

        z-index: 1;
        position: fixed;
        right: 10px;
        top:200px;
    }

    .visualizar-scroll{
        overflow-y: auto;
    }

    /* On mouse-over, add a deeper shadow */
    .component-card:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    .btn-close {
        width: 100%;
        display: inline-block;
        padding-bottom: 5px;
    }
    .close-color {
        color: #1E2245 !important;
    }
    .title-container {
        display: inline;
    }
    .body-container {
        padding-top: 38px;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>